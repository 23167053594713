/* ==========================================================================
 *  Function: Counter
 * ======================================================================= */
function fn_parts_counter(){
	if ($( '.js-counter-group' ).length){
		$('.js-counter-group .js-counter-item').each(function () {
		    $(this).prop('Counter',0).animate({
		        Counter: $(this).attr('data-number')
		    }, {
		        duration: 2000,
		        easing: 'linear',
		        step: function (now) {
		            $(this).text(Math.ceil(now).toLocaleString());
		        }
		    });
		});
	}
}