/* ==========================================================================
 *  Function: navigation
 * ======================================================================= */
function fn_components_side_navigation(){
	if ($('.js-menu-navigation').length){
		checkScroll();
	}
}

var	scrolling = false;
var contentSections = $('.section-scroll'),
	verticalNavigation = $('.js-menu-navigation'),
	navigationItems = verticalNavigation.find('a');

function checkScroll() {
	if( !scrolling ) {
		scrolling = true;
		(!window.requestAnimationFrame) ? setTimeout(updateSections, 300) : window.requestAnimationFrame(updateSections);
	}
}

var firstShowGraph = false;

function updateSections() {
	var halfWindowHeight = $(window).height()/2,
		scrollTop = $(window).scrollTop();
	contentSections.each(function(){
		var section = $(this),
			sectionId = section.attr('id');
			var navigationItem = navigationItems.filter('[href^="#'+ sectionId +'"]');
		if( (section.offset().top - halfWindowHeight < scrollTop ) && ( section.offset().top + section.innerHeight() - halfWindowHeight > scrollTop) ){
			navigationItem.addClass('is-active');
			if ( !firstShowGraph && sectionId == 'section-company'){
				fn_parts_counter();			
				firstShowGraph = true;
			}
		} else {
			navigationItem.removeClass('is-active');
		}
	});
	scrolling = false;
}
