//====================================================
//  Function: Slider
//====================================================
function fn_components_slider(){
	// fn_main_slider();
	fn_product_slider();
}

// function fn_main_slider(){
// 	if ($( '.js-slider-main' ).length) {
// 		$( '.js-slider-main' ).slick({
// 			infinite: true,
// 			dots: true,
// 			slidesToShow: 1,
// 			slidesToScroll: 1,
// 			arrows: true,
// 			autoplay: true,
// 			autoplaySpeed: 3000,
// 			cssEase: 'linear',
// 			speed: 500,
// 			variableWidth: false,
// 			nextArrow: '<span class="slick-arrows style-mobile slick-next"><i class="icon icon-chevron-thin-right"></i></span>',
// 			prevArrow: '<span class="slick-arrows style-mobile slick-prev"><i class="icon icon-chevron-thin-left"></i></span>',
// 			mobileFirst: true
// 		});
// 	}
// }

function fn_product_slider(){
	if ($( '.js-slider-product-main' ).length) {
		$( '.js-slider-product-main' ).each(function(){
			var inst = $(this);
			var sliderThumbnail = inst.next();

			inst.slick({
				infinite: true,
				dots: false,
				slidesToShow:1,
				slidesToScroll: 1,
				autoplay: false,
				arrows: true,
				autoplaySpeed: 2000,
				cssEase: 'linear',
				speed: 500,
				fade: true,
				nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-sipka-R"></i></span>',
				prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-sipka-L"></i></span>',
				variableWidth: false,
				mobileFirst: true,
				asNavFor:  sliderThumbnail
			});

			sliderThumbnail.slick({
				infinite: true,
				dots: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				arrows: false,
				autoplay: false,
				// centerMode: true,
				autoplaySpeed: 2000,
				cssEase: 'linear',
				speed: 500,
				asNavFor: inst ,
				focusOnSelect: true,
				nextArrow: '<span class="slick-arrows style-1 slick-next"><i class="icon icon-chevron-thin-down"></i></span>',
				prevArrow: '<span class="slick-arrows style-1 slick-prev"><i class="icon icon-chevron-thin-up"></i></span>',
				responsive: [
					{
						breakpoint: 1220,
						settings: {
							slidesToShow: 4,
						}
					},
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 4,
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 4,
						}
					},
					{
						breakpoint: 560,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 400,
						settings: {
							slidesToShow: 2,
						}
					},
					{
						breakpoint: 200,
						settings: {
							slidesToShow: 2,
						}
					}
				]
			});

		});
	}

}

