//====================================================
//  Initialization Map
//====================================================


function fn_components_object_map(){
    $('.map-object .map-block').each(function() {
        var inst = $(this);
        var id = inst.attr('id');
        fn_components_map_init(id);
    });
}



function fn_components_map_init(mapId){
	var map
    var mapOptions = {
        zoom: 8,
        panControl: false,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: false,
        //draggable: false,
        center: new google.maps.LatLng(50.0809813, 14.396636),
        mapTypeId: google.maps.MapTypeId.ROADMAP
        // styles:[{"elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#dadada"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c9c9c9"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#64d8ff"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]}]
    };
    map = new google.maps.Map(document.getElementById(mapId), mapOptions);

    var gmarkers = [];
    var bounds = new google.maps.LatLngBounds();
    var objects = [];
    

    function markerSet(image){
        return new google.maps.MarkerImage(
            image,
            new google.maps.Size(43, 58),
            // The origin for my image is 0,0.
            new google.maps.Point(0, 0),
            // The center of the image is 50,50 (my image is a circle with 100,100)
            new google.maps.Point(21, 58)
        );
    }

    var markerN = markerSet('images/marker-n.png');
    var markerT = markerSet('images/marker-t.png');
    var markerD = markerSet('images/marker-d.png');
    
    var markerRestaurant = markerSet('images/marker-restaurant.png');
    var markerShop = markerSet('images/marker-shop.png');
    var markerPark = markerSet('images/marker-park.png');

    var markers = [
        {
            'id': '1',
            'selectable': true,
            'icon': markerN,
            'longitude': 50.041922,
            'latitude': 14.443461,
            'link': '#',
            'image': 'images/product-image-1.jpg',
            'tag': 'volné byty',
            'title': 'NOVOSADSKÝ DVŮR',
            'description': 'Aenean dapibus sem eu dui parketra, dictum scelerisque mauris tempor. Mauris dolor interdum dolor neque.'
        },
        {
            'id': '2',
            'selectable': true,
            'icon': markerT,
            'longitude': 50.091922,
            'latitude': 14.423461,
            'link': '#',
            'image': 'images/product-image-2.jpg',
            'tag': '',
            'title': 'NOVOSADSKÝ DVŮR dlhy text text text text text',
            'description': 'Aenean dapibus sem eu dui pharetra, dictum scelerisque mauris tempor. Mauris dolor interdum dolor neque. bus sem eu dui pharetra, dictum sceler'
        },
        {
            'id': '3',
            'selectable': true,
            'icon': markerD,
            'longitude': 50.011922,
            'latitude': 14.493461,
            'link': '#',
            'image': 'http://www.modelina-architekci.com/wp-content/uploads/2017/05/MA091_Fence-House_HR_02.jpg',
            'tag': '',
            'title': 'NOVOSADSKÝ DVŮR dlhy text text text text text',
            'description': 'Aenean dapibus sem eu dui pharetra, dictum scelerisque mauris tempor. Mauris dolor interdum dolor neque. bus sem eu dui pharetra, dictum sceler'
        },
        {
            'id': '4',
            'selectable': false,
            'icon': markerRestaurant,
            'longitude': 50.021922,
            'latitude': 14.463461
        },
        {
            'id': '5',
            'selectable': false,
            'icon': markerShop,
            'longitude': 50.011922,
            'latitude': 14.409461
        },
        {
            'id': '6',
            'selectable': false,
            'icon': markerPark,
            'longitude': 50.101922,
            'latitude': 14.463461
        }
    ]

    for (var i = 0; i < markers.length; ++i) {
        if (markers[i].selectable){
            var template = '<a href="'+ markers[i].link +'" class="product-content h-margin-05 p-margin-0">' +
                                '<div class="product-text-block order-1 order-md-0">' +
                                    '<div class="product-tag d-none d-md-block">'+ markers[i].tag +'</div>' +
                                    '<div class="delta dots dots-35">'+ markers[i].title +'</div> <p class="dots dots-90">'+ markers[i].description +'</p>' +
                                '</div>' +
                                    '<div class="product-image-block order-0 order-md-1">' +
                                        '<div class="product-tag d-block d-md-none">'+ markers[i].tag +'</div>' +
                                        '<div class="bg-image" style="background-image: url('+ markers[i].image +')"></div>' +
                                '</div>' +
                            '</a>'
            objects.push(template);
        }
    }

    var firstInit = true;
    var windowWidth;
    var windowOffset;

    if ($(window).width() > 768){
        windowWidth = 480;
        windowOffset = new google.maps.Size(300, 165);
    } else {
        windowWidth = 220;
        windowOffset = new google.maps.Size(0, 0);
    } 

    for (var i = 0; i < markers.length; ++i) {
        var latLng = new google.maps.LatLng(markers[i].longitude, markers[i].latitude)
        var marker = new google.maps.Marker({
            position: latLng,
            draggable: false,
            icon: markers[i].icon,
            active: markers[i].selectable,
            id: markers[i].id,
            name: objects[i]
        });

        var infoWindow = new google.maps.InfoWindow({
            maxWidth: windowWidth,
            pixelOffset: windowOffset
        }), marker, i;

        google.maps.event.addListener(marker, 'click', function(e){
            if (this.id.length && this.active){
                infoWindow.setContent(this.name);
                infoWindow.open(map, this);
                if (firstInit){
                    setTimeout(function(){
                        $('.gm-style-iw').parent().addClass('window-block');
                        fn_dots( '.map-object .dots' );
                        firstInit = false;
                    }, 50);
                } else {
                    $('.gm-style-iw').parent().addClass('window-block');
                    fn_dots( '.map-object .dots' );
                }
            }
        });

        google.maps.event.addListener(infoWindow, 'domready', function() {
            var iwOuter = $('.gm-style-iw');
            var iwBackground = iwOuter.prev();
            iwBackground.children(':nth-child(2)').css({'display' : 'none'});
            iwBackground.children(':nth-child(4)').css({'display' : 'none'});

            var iwCloseBtn = iwOuter.next();
            iwCloseBtn.css({width: '40px', height:'40px', opacity: '1', right: '7px', top: '60px', 'border-radius': '100px'});
            iwCloseBtn.mouseout(function(){
                $(this).css({opacity: '1'});
            });

        });


        bounds.extend(marker.position);
        marker.setMap(map);
        gmarkers.push(marker);
    }

    map.fitBounds(bounds);
}

